export default (data = {}) => {
    return {
        account: null,
        store: "",
        code: null,
        category: null,
        subcategory: null,
        product_name: null,
        brand: null,
        reference: null,
        size: 1,
        uom: null,
        normalized_size: 0,
        normalized_uom: null,
        description: null,
        picture: null,
        price: 0,
        discount: 0,
        tax: 0,
        active: null,
        options: null,
        cost: 0,
        cost_uom: 0,
        supplier: null,
        sku: null,
        issue_date: null,
        product_options: "",
        pack: 1,
        utility: 20,
        main: 0,
        ...data
    }
}


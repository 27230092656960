<template>
    <v-container>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6>
                <v-data-table calculate-widths :headers="header_orders" :items="orders" :items-per-page="15"
                    item-key="index" sort-by="name" mobile-breakpoint="0" class="table-cursor elevation-2"
                    loading-text="Cargando ...">
                    <template v-slot:top>
                        <v-toolbar flat rounded>
                            <v-toolbar-title>Pedidos</v-toolbar-title>
                        </v-toolbar>

                    </template>
                    <template v-slot:[`item.total`]="{ item }" class="text-right">
                        {{ "$ " + parseFloat(item.total).toLocaleString() }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="view_doc(item)">
                            mdi-magnify
                        </v-icon>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        <v-bottom-navigation background-color="blue-grey lighten-5" grow app>
            <v-btn to="Inicio">
                <span>Lista de Precios</span>
                <v-icon>mdi-storefront-outline</v-icon>
            </v-btn>
            <v-btn to="MyOrders">
                <span>Recientes</span>
                <v-icon>mdi-history</v-icon>
            </v-btn>
            <v-btn to="Cart">
                <span>Pedido: {{ sc.qty }}</span>
                <v-icon>mdi-cart-outline</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";

export default {
    components: {},
    data: () => ({
        categories: [],
        items: [{
            id: 3,
            name: "Mis pedidos",
            description: "Creear, editar o eliminar pedidos.",
            icon: "mdi-file-document-edit-outline",
            to: "Material",
            color: "warning",
        },
        {
            id: 1,
            name: "Lista de Precios",
            description:
                "Creear, editar o eliminar productos de la lista de precios.",
            icon: "mdi-store-outline",
            color: "warning",
            to: "Proveedores",
        },
        {
            id: 2,
            name: "Proveedores",
            description: "Creear, editar o eliminar proveedores.",
            icon: "mdi-file-document-edit-outline",
            to: "Material",
            color: "warning",
        },

        {
            id: 4,
            name: "Mi perfil",
            description:
                "Editar perfil, logo, información de contacto.",
            icon: "mdi-file-document-edit-outline",
            to: "Material",
            color: "warning",
        },],
        loading_status: false,
        smart_categories: [],
        smart_lst_selected: [],
        smart_lst: [],
        menu: [{
            id: 1,
            name: "Hacer pedido",
            description:
                "Creear, editar o eliminar productos de la lista de precios.",
            icon: "mdi-store-outline",
            color: "warning",
            to: "Inicio",
        },
        {
            id: 2,
            name: "Historico de Pedidos",
            description:
                "Creear, editar o eliminar clientes.",
            icon: "mdi-file-document-edit-outline",
            to: "Cart",
            color: "warning",
        },],
        selectedItem: 0,
        tab: 'orders',
        store_dialog: false,
        itemSelected: createItem(),
        add_dialog: false,
        addItm: false,
        Scart: false,
        sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
        clientDialog: false,
        orders: [],
        header_orders: [
            {
                text: "Fecha",
                align: "start",
                sortable: true,
                value: "issue_date",
                dataType: "date",
            },
            {
                text: "Orden",
                align: "start",
                value: "doc_code",
                dataType: "text",
            },
            {
                text: "Valor",
                align: "end",
                sortable: true,
                value: "total",
            },
            {
                text: "",
                align: "end",
                sortable: false,
                value: "actions",
            },
        ]
    }),
    methods: {

        get_oders() {
            var qry = {
                table: 'docs',
                filters: [
                    { field: "doc_type", operator: "=", value: "OR" },
                    { field: "store", operator: "=", value: "mayorista.app" },
                    { field: "userId", operator: "=", value: this.$store.getters.profile.code },
                ],
            };
            webserver("get_table_clients", qry, (data) => {

                this.orders = data;
            });
        },
        view_doc(e) {
            this.$router.push("Checkout/?did=" + e.doc_code);
        }
    },
    mounted() {


        this.get_oders();

    },
    watch: {
    },
};
</script>

<style>
</style>